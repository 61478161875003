var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (slot, uid) {
      pug_html = pug_html + "\u003Cdiv class=\"well well-sm slot\"\u003E\u003Cdiv class=\"slot-title\"\u003E" + (pug.escape(null == (pug_interp = slot + 1) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"slot-summary\"\u003E";
if (uid) {
pug_html = pug_html + "\u003Cdiv\u003E\u003Cb\u003E" + (pug.escape(null == (pug_interp = "UID: ") ? "" : pug_interp)) + "\u003C\u002Fb\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = uid) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cul class=\"slot-links\"\u003E\u003Cli\u003E\u003Ca class=\"slot-download-link\" href=\"#\"\u003EDownload\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca class=\"slot-upload-link\" href=\"#\"\u003EUpload\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C!--li: a.slot-load-link(href='#') Load from flash--\u003E\u003Cli\u003E\u003Ca class=\"slot-clear-link\" href=\"#\"\u003EClear\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca class=\"slot-select-link\" href=\"#\"\u003ESelect\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "slot" in locals_for_with ?
        locals_for_with.slot :
        typeof slot !== 'undefined' ? slot : undefined, "uid" in locals_for_with ?
        locals_for_with.uid :
        typeof uid !== 'undefined' ? uid : undefined));
    ;;return pug_html;};
module.exports = template;