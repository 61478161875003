var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (buttons) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade\" id=\"alertModal\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close close-modal\" data-close-value=\"255\"\u003E&times;\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003E\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cp\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
if ((buttons && buttons.length > 0)) {
pug_html = pug_html + "\u003Cdiv class=\"modal-footer\"\u003E";
// iterate buttons
;(function(){
  var $$obj = buttons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var button = $$obj[pug_index0];
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-default close-modal\""+pug.attr("data-close-value", button.value, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = button.label) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var button = $$obj[pug_index0];
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-default close-modal\""+pug.attr("data-close-value", button.value, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = button.label) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "buttons" in locals_for_with ?
        locals_for_with.buttons :
        typeof buttons !== 'undefined' ? buttons : undefined));
    ;;return pug_html;};
module.exports = template;